

















































































































































import { defineComponent, computed } from '@vue/composition-api';
import merge from 'lodash/merge';
import { extractKeyValuePairData, formatInteger, formatDate } from '../utils';

export default defineComponent({
  name: 'DynamicReportDBDFeatureData',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const propsData = props.data;

    return {
      propsData,
    };
  },
});
